.suggestions {
  margin: 5em auto 5em !important;
}

.suggestions a:hover .suggestionText {
  text-decoration: underline;
}

@media only screen and (min-width: 768px) {
  .suggestions a > h2 {
    height: 5em;
  }
}

@media only screen and (max-width: 767px) {
  .suggestions h2 {
    padding-left: 10px !important;
  }
}

.suggestions .column:hover {
  background-color: #eee;
}
