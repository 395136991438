.positionChart {
  cursor: pointer;
  background-color: white;
}

.partyNames {
  line-height: 1.2;
  margin-left: -0.2em;
}

.partyNames span {
  padding: 2px 0.3em;
  font-size: 0.9em;
  line-height: 1.1rem;
  display: inline-block;
}

.noText {
  color: rgb(160, 160, 160)
}

.partyNames span:hover {
  cursor: pointer;
}

.chartLabel {
  fill: white;
  opacity: 0.7;
  font-size: 0.9rem;
  cursor: pointer;
}

.chartLabel tspan {
  padding-left: 5px;
}
@media (min-width: 651px) {
  .chartLabel {
    transform: translateX(6px)
  }
}

@media (max-width: 650px) {
  .positionChartFirstElementLabel {
    display: none;
  }
}
