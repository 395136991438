.sources {
  position: relative;
  bottom: 3px;
  right: 0px;
  float: right;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.4);
  text-decoration: underline;
  cursor: pointer;
}

.quizButtons .ui.button {
  min-height: 3em;
  color: #eee;
  font-size: 1.5rem;
}

.quizButtons .ui.button:hover {
  opacity: 0.8;
  color: #eee;
}

.thesisTitleInsert {
  position: absolute;
  margin-right: 1em;
  font-size: 0.9rem;
  line-height: 0.9rem;
}

.thesis-compact {
  height: 50px;
}

.ui.message.positionPopup .header + p {
  margin-top: .5em;
}
